"use client"

import React, { createContext, useState, useEffect, useMemo } from "react"

type ScreenWidthContextType = {
  screenWidth: number | undefined
  isMobile: boolean
}

const ScreenWidthState = createContext<ScreenWidthContextType>({
  screenWidth: undefined,
  isMobile: false,
})

export const ScreenWidthProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [screenWidth, setScreenWidth] = useState<number>()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
      setIsMobile(window.innerWidth <= 999)
    }

    const handleOrientationChange = () => {
      handleResize()
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    window.addEventListener("orientationchange", handleOrientationChange)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [])

  const values = useMemo(
    () => ({ screenWidth, isMobile }),
    [screenWidth, isMobile]
  )

  return (
    <ScreenWidthState.Provider value={values}>
      {children}
    </ScreenWidthState.Provider>
  )
}

export default ScreenWidthState
