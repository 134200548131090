import { useAdminGetSession } from "medusa-react"
import { createContext, useEffect, useReducer, useState } from "react"
import { v4 as uuidV4 } from "uuid"
import { OpenReplayContextType } from "../types/openReplay"

export const OpenReplayContext = createContext<OpenReplayContextType>({
  startTracking: () => {},
  initTracker: () => {},
})

function defaultGetUserId() {
  return uuidV4()
}

async function newTracker(config?: any) {
  // we use dynamic import to make sure that tracker is started inside browser env
  const { default: Tracker } = await import("@openreplay/tracker")
  const getUserId =
    config?.userIdEnabled && config?.getUserId
      ? config.getUserId
      : defaultGetUserId
  let userId = null

  const trackerConfig = {
    projectKey: process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY || "",
    ingestPoint: config.ingestPoint,
  }

  const tracker = new Tracker(trackerConfig)

  if (config?.userIdEnabled) {
    userId = getUserId()
    // @ts-ignore
    tracker.setUserID(userId)
  }
  return tracker
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case "init": {
      if (!state.tracker) {
        newTracker(state.config)
          .then((trackerInstance) => {
            state = { ...state, tracker: trackerInstance }
          })
          .catch((err) => console.error(err))
      }
      return state
    }
    case "start": {
      if (state.tracker) {
        state.tracker.start()
      } else {
        newTracker(state.config)
          .then((trackerInstance) => {
            trackerInstance.start()
          })
          .catch((err) => console.error(err))
      }
      return state
    }
  }
}

export default function OpenReplayProvider({
  children,
  config = {},
}: {
  children: any
  config: any
}) {
  let [state, dispatch] = useReducer(reducer, { tracker: null, config })
  let value = {
    startTracking: () => dispatch({ type: "start" }),
    initTracker: () => dispatch({ type: "init" }),
  }
  const [currentTracker, setCurrentTracker] = useState<any>()
  const { user } = useAdminGetSession()

  useEffect(() => {
    newTracker(config)
      .then((trackerInstance) => {
        trackerInstance.start()
        setCurrentTracker(trackerInstance)
      })
      .catch((err) => console.error(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user && currentTracker) {
      currentTracker.setUserID(user?.email ? user?.email : defaultGetUserId())
    }
  }, [user, currentTracker])

  return (
    <OpenReplayContext.Provider value={value}>
      {children}
    </OpenReplayContext.Provider>
  )
}
