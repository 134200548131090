import axios from "axios"
import { medusaUrl } from "./config"

export const client = axios.create({ baseURL: medusaUrl })

export default function medusaRequest(
  method,
  path = "",
  payload = {},
  headers
) {
  const options = {
    method,
    withCredentials: true,
    url: path,
    data: payload,
    json: true,
    headers,
  }
  return client(options)
}
